import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./RecipesPage.css";
import { type Dispatch } from "redux";
import {
  Card,
  IconButton,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  type Theme,
  Container,
  Paper,
  Grid,
  Divider,
  CardActionArea,
  makeStyles,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import { useNavigate } from "react-router-dom";
import { getRecipesRequest, deleteRecipeRequest } from "../actions/RecipeActionCreators";
import { type RootState } from "../reducers";
import { type RecipeState } from "../reducers/RecipeReducers";
import { setModalPropsAction } from "../actions/GlobalActionCreator";
import { type ModalProps } from "../actions/Actions";
import URLS from "../config";
import Search from "../components/Search";
import { filterRecipes } from "../utilities/helpers";

interface Props {
  recipeState: RecipeState;
  getRecipes: () => void;
  deleteRecipe: (id: number) => void;
  setModalProps: (props: ModalProps) => void;
}
const useStyles = makeStyles(
  (theme: Theme) =>
    ({
      card: {
        margin: theme.spacing(2),
      },
      media: {
        height: 150,
        paddingTop: "56.25%", // 16:9
      },
      recipeTitle: {
        height: 49,
      },
      search: {
        padding: theme.spacing(2),
        "& .MuiInputBase": {
          boxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        },
        "& .MuiInputBase-adornedStart": {
          boxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        },
      },
    } as const)
);
function RecipesPage(props: Props) {
  const navigate = useNavigate();
  const {
    getRecipes,
    recipeState: { isLoading, recipes },
    deleteRecipe,
    setModalProps,
  } = props;
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState<string>("");
  useEffect(() => {
    if (isLoading) {
      getRecipes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  const filteredRecipes = filterRecipes(Object.entries(recipes), searchTerm);
  const recipeList = filteredRecipes.map(([id, recipe]) => {
    return (
      <Grid key={id} item xs={12} sm={4} lg={3}>
        <Card raised className={classes.card}>
          <CardActions disableSpacing>
            <IconButton
              aria-label="edit-recipe"
              color="secondary"
              onClick={() => {
                navigate(`/recipe/${recipe.id}/edit`);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete-recipe"
              color="secondary"
              onClick={() => {
                const modalProps: ModalProps = {
                  open: true,
                  title: "Confirmation",
                  msg: "Are you sure?",
                  btn1Text: "No",
                  btn2OnClick: () => {
                    if (recipe.id) {
                      deleteRecipe(recipe.id);
                    }
                  },
                  btn2Text: "Yes",
                  btn2Color: "primary",
                };
                setModalProps(modalProps);
              }}
            >
              <CancelIcon />
            </IconButton>
          </CardActions>
          <CardActionArea
            onClick={() => {
              navigate(`/recipe/${recipe.id}`);
            }}
          >
            <CardMedia
              className={classes.media}
              image={
                recipe.imageId ? `${URLS.database}/asset/image/${recipe.imageId}` : "https://via.placeholder.com/150"
              }
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom color="primary" component="h6" className={`line-clamp ${classes.recipeTitle}`}>
                {recipe.name}
              </Typography>
            </CardContent>
            <Divider />
            <CardContent>
              <Typography
                variant="body2"
                color="inherit"
                style={{
                  display: "flex",
                }}
              >
                <AccessTimeIcon fontSize="small" />
                {recipe.timeMinutes} min
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  });
  return (
    <Container>
      <Paper elevation={0} className="paper">
        <Search
          className={classes.search}
          value={searchTerm}
          onChange={(value: string) => {
            setSearchTerm(value);
          }}
        />
        {recipeList.length > 0 ? <Grid container>{recipeList}</Grid> : <Typography>Recipe list is empty</Typography>}
      </Paper>
    </Container>
  );
}

const mapStateToProps = (state: RootState) => ({
  recipeState: state.recipeState,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getRecipes: () => dispatch(getRecipesRequest()),
  deleteRecipe: (id: number) => {
    dispatch(deleteRecipeRequest(id, dispatch));
  },
  setModalProps: (props: ModalProps) => {
    dispatch(setModalPropsAction(props));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(RecipesPage);
