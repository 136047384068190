import React, { Component } from "react";
import { connect } from "react-redux";
import { type Dispatch } from "redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  ListItemAvatar,
  Avatar,
  TextField,
} from "@material-ui/core";
import { type RootState } from "../reducers";
import { type RecipeState } from "../reducers/RecipeReducers";
import { type Ingredient, type Recipe, type Item } from "../types";
import { type ShoppingListState } from "../reducers/ShoppingListReducers";
import { createShoppingListRequest, updateShoppingListRequest } from "../actions/ShoppingListActionCreators";
import URLS from "../config";
import Search from "./Search";
import { filterRecipes } from "../utilities/helpers";

interface Props {
  onClose: () => void;
  open: boolean;
  recipeState: RecipeState;
  shoppingListState: ShoppingListState;
  getRecipes: () => void;
  createShoppingList: (name: string, items: Item[]) => void;
  updateShoppingList: (id: number, name: string, items: Item[]) => void;
}
interface State {
  step: number;
  recipeId: number | undefined;
  shoppingListId: number | undefined;
  shoppingListName: string;
  createNewShoppingList: boolean;
  items: Record<string, Item>;
  searchTerm: string;
}
const initialState: Readonly<State> = {
  step: 0,
  recipeId: undefined,
  shoppingListId: undefined,
  shoppingListName: "",
  createNewShoppingList: false,
  items: {},
  searchTerm: "",
};
class ImportItemModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  public componentDidMount() {
    const { getRecipes } = this.props;
    getRecipes();
  }

  onImport(name: string, id?: number) {
    const { createShoppingList, updateShoppingList, shoppingListState } = this.props;
    const { items } = this.state;
    const itemsToImport: Item[] = [];
    Object.entries(items).forEach(([ingredientId, item]) => {
      if (item.checked) {
        itemsToImport.push({
          ...item,
          checked: false,
        });
      }
    });
    if (itemsToImport.length > 0) {
      if (id) {
        let existingItems: Item[] = [];
        shoppingListState.shoppingLists.forEach((list) => {
          if (list.id === id) {
            existingItems = list.items;
          }
        });
        updateShoppingList(id, name, existingItems.concat(itemsToImport));
      } else {
        createShoppingList(name, itemsToImport);
      }
    }
    this.onClose();
  }

  selectRecipe = (id: number) => {
    const { recipeState } = this.props;
    const recipe = recipeState.recipes[id];
    const { ingredients } = recipe;

    const items: Record<string, Item> = ingredients.reduce((accumulator: Record<string, Item>, current: Ingredient) => {
      const { name, amount, unit } = current;
      return {
        ...accumulator,
        [current.id]: {
          checked: true,
          name,
          amount,
          unit,
        },
      };
    }, {});
    this.setState((prev) => ({
      ...prev,
      recipeId: id,
      shoppingListName: recipe.name,
      items,
    }));
    this.next();
  };

  back = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  next = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  backToRecipes = () => {
    this.setState((prev) => ({
      ...initialState,
      step: prev.step,
    }));
    this.back();
  };

  handleToggle = (id: string) => {
    const { items } = this.state;
    const item = items[id];
    this.setState((prev) => ({
      ...prev,
      items: {
        ...items,
        [id]: {
          ...item,
          checked: !item.checked,
        },
      },
    }));
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose();
    this.setState(initialState);
  };

  render() {
    const { onClose, open, recipeState, shoppingListState } = this.props;
    const { items, searchTerm } = this.state;
    const filteredRecipes = filterRecipes(Object.entries(recipeState.recipes), searchTerm);
    const recipes = filteredRecipes.map(([id, recipe]: [string, Recipe]) => {
      return (
        <ListItem
          key={id}
          button
          onClick={() => {
            this.selectRecipe(parseInt(id, 10));
          }}
        >
          <ListItemAvatar>
            <Avatar src={`${URLS.database}/asset/image/${recipe.imageId}`} />
          </ListItemAvatar>
          <ListItemText id={id} primary={recipe.name} />
        </ListItem>
      );
    });
    const getDialogContent = (): JSX.Element | undefined => {
      const { step } = this.state;
      switch (step) {
        // Choose a recipe
        case 0:
          return (
            <>
              <DialogTitle>Recipes</DialogTitle>
              <DialogContent dividers>
                <Search
                  value={searchTerm}
                  onChange={(value: string) => {
                    this.setState({ searchTerm: value });
                  }}
                />
                <List dense>{recipes}</List>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="default">
                  Close
                </Button>
              </DialogActions>
            </>
          );
        // select all or some items
        case 1: {
          const itemlist = Object.entries(items).map(([id, item]) => {
            const { amount, name, checked } = item;
            return (
              <ListItem
                key={id}
                dense
                button
                onClick={() => {
                  this.handleToggle(id);
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked}
                    onChange={() => {
                      this.setState({
                        items: {
                          ...items,
                          [id]: {
                            ...items[id],
                            checked: !checked,
                          },
                        },
                      });
                    }}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": id,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={id}
                  primary={`${amount === 0 ? "" : amount} ${item.unit === "" ? "" : `${item.unit} `}${name}`}
                />
              </ListItem>
            );
          });
          const { recipeId } = this.state;
          return (
            <>
              <DialogTitle>{recipeState.recipes[recipeId!].name}</DialogTitle>
              <DialogContent dividers>{itemlist}</DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.backToRecipes();
                  }}
                  color="default"
                >
                  Back
                </Button>
                <Button
                  onClick={() => {
                    this.next();
                  }}
                  color="primary"
                >
                  Next
                </Button>
              </DialogActions>
            </>
          );
        }
        // Import to an existing shopping list or a new shoppinglist
        case 2: {
          const shoppingLists = shoppingListState.shoppingLists.map((list) => {
            return (
              <ListItem
                key={list.id}
                button
                onClick={() => {
                  this.onImport(list.name, list.id);
                }}
              >
                <ListItemText>
                  <Button
                    fullWidth
                    style={{
                      textTransform: "none",
                    }}
                    color="default"
                    variant="outlined"
                    onClick={() => {
                      this.setState({
                        createNewShoppingList: true,
                      });
                    }}
                  >
                    {list.name}
                  </Button>
                </ListItemText>
              </ListItem>
            );
          });
          const { createNewShoppingList, shoppingListName } = this.state;
          return (
            <>
              <DialogTitle>
                {createNewShoppingList ? "Create a new shopping list" : "Import to an existing list"}
              </DialogTitle>

              {createNewShoppingList ? (
                <>
                  <DialogContent dividers>
                    <List dense>
                      <ListItem>
                        <Button
                          fullWidth
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            this.setState({
                              createNewShoppingList: false,
                            });
                          }}
                        >
                          Import to existing list{" "}
                        </Button>
                      </ListItem>
                      <ListItem>
                        <TextField
                          fullWidth
                          size="small"
                          label="Name"
                          value={shoppingListName}
                          variant="outlined"
                          onChange={(e) => {
                            this.setState({
                              shoppingListName: e.target.value,
                            });
                          }}
                        />
                      </ListItem>
                    </List>
                  </DialogContent>
                  <DialogActions>
                    <Button color="default" onClick={this.back}>
                      Back
                    </Button>
                    <Button
                      onClick={() => {
                        this.onImport(shoppingListName, undefined);
                      }}
                      color="primary"
                    >
                      Create
                    </Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <DialogContent dividers>
                    <List dense>
                      <ListItem>
                        <Button
                          fullWidth
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            this.setState({
                              createNewShoppingList: true,
                            });
                          }}
                        >
                          Create a shopping list{" "}
                        </Button>
                      </ListItem>
                      {shoppingLists}
                    </List>
                  </DialogContent>
                  <DialogActions>
                    <Button color="default" onClick={this.back}>
                      Back
                    </Button>
                  </DialogActions>
                </>
              )}
            </>
          );
        }
        default:
          return undefined;
      }
    };
    return (
      <Dialog onClose={this.onClose} fullWidth maxWidth="sm" open={open}>
        {getDialogContent()}
      </Dialog>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  recipeState: state.recipeState,
  shoppingListState: state.shoppingListState,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  createShoppingList: (name: string, items: Item[]) => dispatch(createShoppingListRequest(dispatch, name, items)),
  updateShoppingList: (id: number, name: string, items: Item[]) => dispatch(updateShoppingListRequest(id, name, items)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ImportItemModal);
