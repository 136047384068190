import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import rootReducer from "./reducers";
import apiMiddleware from "./api";
import authMiddleware from "./api/Auth";
import "./index.css";

export const store = createStore(
  rootReducer,
  applyMiddleware(apiMiddleware, authMiddleware)
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const defaultTheme = createTheme();
export const theme = createTheme({
  palette: {
    primary: {
      light: "#4a837f",
      main: "#1a5653",
      dark: "#002d2b",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#59a793",
      main: "#277865",
      dark: "#004b3b",
      contrastText: "#eceff1",
    },
  },
  overrides: {
    MuiContainer: {
      maxWidthLg: {
        [defaultTheme.breakpoints.down("sm")]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        textTransform: "none",
      },
    },
  },
  typography: {
    fontFamily: ["Raleway", "sans-serif"].join(","),
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
