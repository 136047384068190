import URLS from "../config";
import {
  type RefreshTokenAction,
  type RefreshTokenRequest,
  type OnSignedInAction,
  type SignOutAction,
  type SignOutRequest,
  type AuthStartAction,
  type AuthEndAction,
  type SignInRequest,
} from "./Actions";
import { AuthActionTypes as auth } from "./ActionTypes";
import { setAlertPropsAction } from "./GlobalActionCreator";
import { getErrorAlertProps } from "../components/Alert";

export function refreshTokenAction(isAuthenticated: boolean): RefreshTokenAction {
  return {
    type: auth.REFRESH_TOKEN,
    isAuthenticated,
  };
}
export function refreshTokenRequest(): RefreshTokenRequest {
  return {
    type: auth.AUTH,
    payload: {
      url: URLS.refreshToken,
      method: "google",
      onSuccess: () => refreshTokenAction(true),
      onFailure: (error: Error) => {
        refreshTokenAction(false);
        return setAlertPropsAction(getErrorAlertProps(error.message));
      },
      label: auth.REFRESH_TOKEN,
    },
  };
}
export function onSignedInAction(isAuthenticated: boolean): OnSignedInAction {
  return {
    type: auth.SIGNED_IN,
    isAuthenticated,
  };
}
export function signInRequest(googleUser: any): SignInRequest {
  return {
    type: auth.AUTH,
    payload: {
      url: URLS.signIn,
      method: "google",
      onSuccess: () => onSignedInAction(true),
      onFailure: (error: Error) => {
        onSignedInAction(false);
        return setAlertPropsAction(getErrorAlertProps(error.message));
      },
      token: googleUser.getAuthResponse().id_token,
      label: auth.SIGNED_IN,
    },
  };
}
export function signOutAction(isAuthenticated: boolean): SignOutAction {
  return {
    type: auth.SIGN_OUT,
    isAuthenticated,
  };
}

export function signOutRequest(): SignOutRequest {
  return {
    type: auth.AUTH,
    payload: {
      url: URLS.signOut,
      method: "POST",
      onSuccess: (): SignOutAction => signOutAction(false),
      onFailure: (error: Error) => {
        signOutAction(false);
        return setAlertPropsAction(getErrorAlertProps(error.message));
      },
      label: auth.SIGN_OUT,
    },
  };
}
export function authStart(): AuthStartAction {
  return {
    type: auth.AUTH_START,
  };
}
export function authEnd(): AuthEndAction {
  return {
    type: auth.AUTH_END,
  };
}
