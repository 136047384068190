import React, { useState } from "react";
import { Button, Grid, IconButton, makeStyles, TextField, Tooltip } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import nextId from "react-id-generator";

interface Props {
  id?: string;
  onAdd?: (ingredient: string) => void;
  onChange?: (value: string, id: string) => void;
  onDelete?: (id: string) => void;
  initialValue: string;
  multiline: boolean;
  tooltip: string;
}
const style = makeStyles(() => ({
  button: {
    position: "absolute",
    right: "5px",
    top: "-15px",
    zIndex: 2,
  },
  textField: {
    flexGrow: 1,
    marginRight: "9px",
  },
}));

function IngredientForm(props: Props) {
  const { onAdd, onChange, onDelete, initialValue, multiline, tooltip, id: ingId } = props;
  const [ingredient, setIngredient] = useState(initialValue);
  let id = ingId || nextId();
  const clearState = () => {
    setIngredient(initialValue);
    id = nextId();
  };
  const classes = style();
  return (
    <Grid
      item
      container
      justifyContent="space-between"
      style={{
        position: "relative",
      }}
    >
      <Tooltip title={tooltip} placement="top" arrow disableFocusListener>
        <TextField
          className={classes.textField}
          multiline={multiline}
          variant="outlined"
          value={ingredient}
          label="Ingredient Name"
          size="small"
          name="name"
          onChange={(
            e: React.ChangeEvent<{
              value: string;
            }>
          ) => {
            setIngredient(e.target.value);
            if (onChange) {
              onChange(e.target.value, id);
            }
          }}
        />
      </Tooltip>
      {onAdd && (
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => {
            if (ingredient !== "") {
              onAdd(ingredient);
              clearState();
            }
          }}
        >
          Add
        </Button>
      )}
      {onDelete && (
        <IconButton
          className={classes.button}
          color="primary"
          size="medium"
          edge="end"
          onClick={() => {
            if (id) {
              onDelete(id);
            }
          }}
        >
          <CancelIcon />
        </IconButton>
      )}
    </Grid>
  );
}

IngredientForm.defaultProps = {
  id: nextId(),
  onAdd: undefined,
  onChange: undefined,
  onDelete: undefined,
};

export default IngredientForm;
