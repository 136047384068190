import React, { Component } from "react";
import { connect } from "react-redux";
import { GoogleLoginButton } from "ts-react-google-login-component";
import { type Dispatch } from "redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { signInRequest } from "../actions/AuthActionCreators";

interface Props {
  signIn: (googleUser: gapi.auth2.GoogleUser) => void;
  onHide: () => void;
  show: boolean;
}

class LoginModal extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.responseGoogle = this.responseGoogle.bind(this);
  }

  private responseGoogle(googleUser: gapi.auth2.GoogleUser): void {
    const { signIn, onHide } = this.props;
    signIn(googleUser);
    onHide();
  }

  // eslint-disable-next-line class-methods-use-this
  private preLoginTracking(): void {
    console.log("Attemp to login with google");
  }

  // eslint-disable-next-line class-methods-use-this
  private errorHandler(error: string): void {
    // handle error if login got failed...
    console.error("error", error);
  }

  render() {
    const { onHide, show } = this.props;
    const clientConfig = {
      client_id: "400101750794-igk0i2nn7ln5a97s9na47da85822cr61.apps.googleusercontent.com",
      cookie_policy: "single_host_origin",
    };
    const renderOptions = {
      longtitle: true,
      theme: "dark",
    };
    return (
      <Dialog onClose={onHide} open={show}>
        <DialogTitle>Log In</DialogTitle>
        <DialogContent dividers>
          <GoogleLoginButton
            responseHandler={this.responseGoogle}
            clientConfig={clientConfig}
            preLogin={this.preLoginTracking}
            failureHandler={this.errorHandler}
            renderOptions={renderOptions}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onHide} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  signIn: (googleUser: gapi.auth2.GoogleUser) => dispatch(signInRequest(googleUser)),
});
export default connect(null, mapDispatchToProps)(LoginModal);
