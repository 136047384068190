import {
  Divider,
  Grid,
  List,
  ListItemText,
  Paper,
  Typography,
  type Theme,
  makeStyles,
  Button,
  useMediaQuery,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import React, { useState } from "react";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import BrightnessLowIcon from "@material-ui/icons/BrightnessLow";
import NoSleep from "nosleep.js";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import { type Dispatch } from "redux";
import { connect } from "react-redux";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { deleteRecipeRequest } from "../actions/RecipeActionCreators";
import { setModalPropsAction } from "../actions/GlobalActionCreator";
import { type Ingredient, type Recipe } from "../types";
import { type ModalProps } from "../actions/Actions";
import URLS from "../config";

const style = makeStyles((theme: Theme) => ({
  fab: {
    position: "fixed",
    [theme.breakpoints.up("sm")]: {
      bottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      bottom: theme.spacing(8),
    },
    right: theme.spacing(2),
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      margin: "2rem",
    },
  },
}));
interface Props {
  editView: boolean;
  recipe: Recipe;
  deleteRecipe: (id: number) => void;
  setModalProps: (props: ModalProps) => void;
}
function RecipeComponent(props: Props) {
  const navigate = useNavigate();
  const smallScreen = useMediaQuery("(max-width:600px)");
  const { editView, recipe, deleteRecipe, setModalProps } = props;
  const classes = style();
  const [disableSleep, setNoSleep] = useState(false);
  const noSleep = new NoSleep();
  return (
    <Paper elevation={2} className={classes.paper}>
      {editView ? null : (
        <>
          <AppBar position="static" color="transparent" elevation={1}>
            <Toolbar
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                onClick={() => {
                  navigate(`/recipe/${recipe.id}/edit`);
                }}
                color="primary"
                size="small"
                startIcon={smallScreen ? null : <EditIcon />}
              >
                Edit Recipe
              </Button>
              <Button
                color="primary"
                variant="text"
                size="small"
                startIcon={smallScreen ? null : <CancelIcon />}
                onClick={() => {
                  const modalProps: ModalProps = {
                    open: true,
                    title: "Confirmation",
                    msg: "Are you sure?",
                    btn1Text: "No",
                    btn2OnClick: () => {
                      deleteRecipe(recipe.id!);
                      navigate("/recipes");
                    },
                    btn2Text: "Yes",
                    btn2Color: "primary",
                  };
                  setModalProps(modalProps);
                }}
              >
                Delete Recipe
              </Button>
              <Button
                onClick={() => {
                  setNoSleep(!disableSleep);
                  if (disableSleep) {
                    noSleep.enable();
                  } else {
                    noSleep.disable();
                  }
                }}
                color="primary"
                size="small"
                startIcon={smallScreen && disableSleep ? <BrightnessLowIcon /> : <BrightnessHighIcon />}
              >
                {disableSleep ? "Allow screen sleep" : "Prevent screen sleep"}
              </Button>
            </Toolbar>
          </AppBar>
          <Divider />
        </>
      )}

      <div
        style={{
          padding: "1rem",
        }}
      >
        <Typography color="primary" variant={smallScreen ? "h5" : "h4"} align="center">
          {recipe.name || "Unnamed"}
        </Typography>

        {recipe.imageId ? (
          <img
            style={{
              objectFit: "cover",
            }}
            className="img"
            src={`${URLS.database}/asset/image/${recipe.imageId}`}
            alt=""
          />
        ) : null}
        {recipe.originalUrl && (
          <Link href={recipe.originalUrl} underline="none" target="_blank" rel="noopener">
            Original link to recipe
          </Link>
        )}
        <Typography color="textSecondary" component="div" paragraph>
          {recipe.description}
        </Typography>
        <Grid xs={12} item className="content-separator">
          <Typography
            className="content-separator-text"
            component="p"
          >{`Servings: ${recipe.numberOfPortions} portions`}</Typography>
          <Typography
            className="content-separator-text"
            component="p"
          >{`Cooking time: ${recipe.timeMinutes} min`}</Typography>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={4}>
            <Typography color="primary" variant="h6">
              Ingredients
            </Typography>
            <List>
              {recipe.ingredients.map((ingredient: Ingredient) => {
                const { amount, unit, name } = ingredient;
                return (
                  <React.Fragment key={ingredient.id}>
                    <ListItemText>
                      <Typography>{`${amount === 0 ? "" : amount} ${unit} ${name}`}</Typography>
                    </ListItemText>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          </Grid>

          <Grid item xs={12} lg={8}>
            <Typography color="primary" variant="h6">
              Instructions
            </Typography>
            <ol>
              {recipe.instructions.map(({ id, text }) => {
                return (
                  <React.Fragment key={id}>
                    <li key={id} className=" step">
                      <p>{text}</p>
                    </li>
                  </React.Fragment>
                );
              })}
            </ol>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteRecipe: (id: number) => {
    dispatch(deleteRecipeRequest(id, dispatch));
  },
  setModalProps: (props: ModalProps) => {
    dispatch(setModalPropsAction(props));
  },
});

export default connect(null, mapDispatchToProps)(RecipeComponent);
