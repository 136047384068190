import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
import { type Dispatch } from "redux";
import {
  ListItemText,
  ListItem,
  Checkbox,
  IconButton,
  ListItemSecondaryAction,
  TextField,
  ListItemIcon,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { type Item as ItemType } from "../types";
import { deleteItemRequest, updateItemRequest } from "../actions/ShoppingListActionCreators";
import { parseIngredient, renderIngredient } from "../utilities/helpers";

interface Props {
  checked: boolean;
  item: ItemType;
  deleteItem: (item: ItemType) => void;
  toggleItem: (item: ItemType) => void;
  updateItem: (item: ItemType) => void;
}
interface State {
  editing: boolean;
  itemString: string;
}
function ItemComponent(props: Props) {
  const { item, toggleItem, deleteItem, updateItem } = props;
  const { amount, unit, name, checked } = item;
  const [editing, setEditing] = useState<boolean>(false);
  const [itemString, setItemString] = useState<string>(renderIngredient(amount, unit, name));

  const edit = () => {
    setEditing(true);
  };
  const save = () => {
    const { amount: a, name: n } = parseIngredient(itemString);
    if (item.amount !== a || item.name !== n) {
      updateItem({
        ...item,
        amount: a,
        unit: "",
        name: n,
      });
    }
    setEditing(false);
  };
  const handleToggle = () => {
    if (!editing) {
      toggleItem(item);
    }
  };
  return (
    <ListItem key={item.id} dense button onClick={handleToggle} disableGutters>
      <>
        <ListItemIcon>
          <Checkbox edge="start" checked={checked} tabIndex={-1} disableRipple />
        </ListItemIcon>
        {editing ? (
          <TextField
            fullWidth
            variant="standard"
            value={itemString}
            onChange={(e) => {
              setItemString(e.target.value);
            }}
          />
        ) : (
          <ListItemText primary={itemString} />
        )}
      </>
      <ListItemSecondaryAction>
        {editing ? (
          <IconButton color="secondary" onClick={save} edge="end" aria-label="edit-button">
            <SaveIcon />
          </IconButton>
        ) : (
          <IconButton color="secondary" onClick={edit} edge="end" aria-label="edit-button">
            <EditIcon />
          </IconButton>
        )}
        <IconButton
          color="secondary"
          onClick={() => {
            deleteItem(item);
          }}
          edge="end"
          aria-label="delete-button"
        >
          <CancelIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteItem: (item: ItemType) => dispatch(deleteItemRequest(item)),
  toggleItem: (item: ItemType) =>
    dispatch(
      updateItemRequest({
        ...item,
        checked: !item.checked,
      })
    ),
  updateItem: (item: ItemType) => dispatch(updateItemRequest(item)),
});
export default connect(null, mapDispatchToProps)(ItemComponent);
