// Authentication Actions
export enum AuthActionTypes {
  REFRESH_TOKEN = "REFRESH_TOKEN",
  AUTH = "AUTH",
  SIGNED_IN = "SIGNED_IN",
  SIGN_OUT = "SIGN_OUT",
  AUTH_START = "AUTH_START",
  AUTH_END = "AUTH_END",
}
export enum APIActionType {
  API = "API",
}
export enum RecipeActionTypes {
  CREATE_RECIPE = "CREATE_RECIPE",
  UPDATE_RECIPE = "UPDATE_RECIPE",
  DELETE_RECIPE = "DELETE_RECIPE",
  GET_RECIPES = "GET_RECIPES",
  RECIPE_API_START = "RECIPE_API_START",
  RECIPE_API_END = "RECIPE_API_END",
  CREATE_IMAGE = "CREATE_IMAGE",
  DELETE_IMAGE = "DELETE_IMAGE",
  UPDATE_IMAGE = "UPDATE_IMAGE",
}
export enum ShoppingListActionType {
  CREATE_SHOPPING_LIST = "CREATE_SHOPPING_LIST",
  UPDATE_SHOPPING_LIST = "UPDATE_SHOPPING_LIST",
  DELETE_SHOPPING_LIST = "DELETE_SHOPPING_LIST",
  GET_SHOPPING_LIST = "GET_SHOPPING_LIST",
  UPDATE_ITEM = "UPDATE_ITEM",
  DELETE_ITEM = "DELETE_ITEM",
  SHOPPING_LIST_API_START = "SHOPPING_LIST_API_START",
  SHOPPING_LIST_API_END = "SHOPPING_LIST_API_END",
}
export enum GlobalActionType {
  SET_MODAL_PROPS = "SET_MODAL_PROPS",
  SET_ALERT_PROPS = "SET_ALERT_PROPS",
}
