import React, { useState } from "react";
import { connect } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { type Dispatch } from "redux";
import {
  type Theme,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  TextField,
  makeStyles,
  Typography,
  AccordionDetails,
  withStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SaveIcon from "@material-ui/icons/Save";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordion from "@material-ui/core/Accordion";
import { setModalPropsAction } from "../actions/GlobalActionCreator";
import { type ModalProps } from "../actions/Actions";
import { type Item } from "../types";
import ItemComponent from "./ItemComponent";
import ItemForm from "./ItemForm";
import { deleteShoppingListRequest, updateShoppingListRequest } from "../actions/ShoppingListActionCreators";

interface Props {
  updateShoppingList: (id: number, name: string, items: Item[]) => void;
  deleteShoppingList: (id: number) => void;
  setModalProps: (props: ModalProps) => void;
  id: number;
  listName: string;
  items: Item[];
}
interface State {
  editing: boolean;
  text: string;
  expandList: boolean;
  expandCheckedItems: boolean;
}
const inititalState: Readonly<State> = {
  editing: false,
  text: "",
  expandList: false,
  expandCheckedItems: false,
};
const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      marginBottom: "16px",
    },
  },
  expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
  root: {
    flexFlow: "row-reverse",
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    justifyContent: "space-between",
    margin: 0,
    alignItems: "center",
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);
const useStyles = makeStyles((theme: Theme) => ({
  checked: {
    opacity: 0.5,
  },
  heading: {
    color: theme.palette.primary.main,
  },
}));
function ShoppingListComponent(props: Props) {
  const { updateShoppingList, id, listName, items, deleteShoppingList, setModalProps } = props;

  const [state, setState] = useState<State>({
    ...inititalState,
    text: listName,
  });
  const { expandCheckedItems, editing, text } = state;
  const classes = useStyles();
  const edit = () => {
    setState({
      ...state,
      text: listName,
      editing: true,
    });
  };
  const save = () => {
    if (listName !== text && text !== "") {
      updateShoppingList(id, text, items);
    }
    setState({
      ...state,
      editing: false,
    });
  };
  const toggleExpandCheckedItems = () => {
    setState({
      ...state,
      expandCheckedItems: !expandCheckedItems,
    });
  };

  const itemList = items.reduce((result, item) => {
    if (!item.checked) {
      result.push(<ItemComponent checked={item.checked} key={item.id} item={item} />);
    }
    return result;
  }, new Array<JSX.Element>());

  const checkedItemList = items.reduce((result, item) => {
    if (item.checked) {
      result.push(<ItemComponent checked={item.checked} key={item.id} item={item} />);
    }
    return result;
  }, new Array<JSX.Element>());
  return (
    <Accordion>
      <AccordionSummary
        IconButtonProps={{
          edge: "start",
          color: "primary",
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {editing ? (
          <form noValidate autoComplete="off">
            <TextField
              fullWidth
              value={text}
              variant="standard"
              onChange={(e) => {
                setState({
                  ...state,
                  text: e.target.value,
                });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  save();
                }
              }}
            />
          </form>
        ) : (
          <Typography className={classes.heading}>{listName}</Typography>
        )}
        <div>
          {editing ? (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                save();
              }}
              color="primary"
              edge="end"
              aria-label="save-button"
            >
              <SaveIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                edit();
              }}
              color="primary"
              edge="end"
              aria-label="edit-button"
            >
              <EditIcon />
            </IconButton>
          )}
          <IconButton
            edge="end"
            aria-label="delete-button"
            color="primary"
            onClick={(event) => {
              event.stopPropagation();
              const modalProps: ModalProps = {
                open: true,
                title: "Confirmation",
                msg: "Are you sure?",
                btn1Text: "No",
                btn2Text: "Yes",
                btn2Color: "primary",
                btn2OnClick: () => {
                  deleteShoppingList(id);
                },
              };
              setModalProps(modalProps);
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <List
          style={{
            width: "100%",
          }}
          component="div"
          disablePadding
          // className={classes.nested}
        >
          <ItemForm shoppingListId={id} listName={listName} items={items} />
          {itemList}
          <ListItem onClick={toggleExpandCheckedItems}>
            {expandCheckedItems ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            <ListItemText>{expandCheckedItems ? "Hide checked items" : "Show checked items"}</ListItemText>
          </ListItem>
          <Collapse in={expandCheckedItems} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {checkedItemList}
            </List>
          </Collapse>
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateShoppingList: (id: number, name: string, items: Item[]) => dispatch(updateShoppingListRequest(id, name, items)),
  deleteShoppingList: (id: number) => dispatch(deleteShoppingListRequest(id, dispatch)),
  setModalProps: (props: ModalProps) => {
    dispatch(setModalPropsAction(props));
  },
});
export default connect(null, mapDispatchToProps)(ShoppingListComponent);
