import React, { useEffect, useState } from "react";
// import { Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { type Dispatch } from "redux";
import {
  CircularProgress,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  Paper,
  Select,
  TextField,
  type Theme,
  Typography,
  withStyles,
  Button,
} from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import nextId from "react-id-generator";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useParams } from "react-router-dom";
import {
  updateRecipeRequest,
  getRecipesRequest,
  createImageRequest,
  updateImageRequest,
} from "../actions/RecipeActionCreators";
import { type Recipe, type Ingredient, CATEGORY } from "../types";
import { type RootState } from "../reducers";
import { type RecipeState } from "../reducers/RecipeReducers";
import IngredientForm from "./IngredientForm";

import InstructionForm from "./InstructionForm";
import RecipeComponent from "./RecipeComponent";
import { setModalPropsAction } from "../actions/GlobalActionCreator";
import { type ModalProps } from "../actions/Actions";
import { parseIngredient } from "../utilities/helpers";

interface Props {
  updateRecipe: (recipe: Recipe) => void;
  getRecipes: () => void;
  createImage: <F extends (image: { id: number; url: string }) => void>(
    format: string,
    base64Data: string,
    callback: F
  ) => void;
  updateImage: <F extends () => void>(id: number, format: string, base64Data: string, callback: F) => void;
  setModalProps: (props: ModalProps) => void;
  recipeState: RecipeState;
  classes: Record<"leftPart", string>;
}

const emptyRecipe: Recipe = {
  name: "",
  description: "",
  originalUrl: "",
  timeMinutes: 1,
  numberOfPortions: 2,
  category: CATEGORY.dinner,
  ingredients: [],
  instructions: [],
};
function RecipeForm({
  recipeState: { isLoading, recipes },
  getRecipes,
  createImage,
  updateImage,
  setModalProps,
  updateRecipe,
  classes,
}: Props) {
  const [changed, setChanged] = useState(false);
  const [recipe, setRecipe] = useState(emptyRecipe);
  const [expandIngredients, setExpandIngredients] = useState(true);
  const [expandInstructions, setExpandInstructions] = useState(true);
  const [loading, setLoading] = useState(true);
  const { id: param } = useParams();
  const id = param ? parseInt(param, 10) : -1;
  useEffect(() => {
    if (isLoading) {
      getRecipes();
    } else {
      const r = recipes[id];
      if (r && r.id !== recipe.id) {
        setRecipe(r);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, id]);

  const handleChangeInRecipe = (attribute: string, value: string | number | unknown[]) => {
    setRecipe((prev) => ({
      ...prev,
      [attribute]: value,
    }));
    setChanged(true);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const { files } = target;
    const reader = new FileReader();
    const callback = (image: { id: number; url: string }) => {
      setRecipe((prev) => ({
        ...prev,
        imageId: image.id,
        imageUrl: image.url,
      }));
    };
    reader.addEventListener("load", (result) => {
      const base64Data = btoa(result.target?.result as string);
      const fileSize = files![0].size / 1024 / 1024; // MB
      const { type } = files![0];
      if (fileSize > 1) {
        const modalProps: ModalProps = {
          open: true,
          title: "Error",
          msg: "File too large. Size limit is 1MB",
          btn1Text: "Ok",
          btn1Color: "primary",
        };
        setModalProps(modalProps);
        return;
      }
      if (!recipe.imageId) {
        createImage(type, base64Data, callback);
        setChanged(true);
      } else {
        updateImage(recipe.imageId, type, base64Data, () => {
          setRecipe((prev) => ({
            ...prev,
            imageUrl: `${recipe.imageUrl}?${new Date().getTime()}`,
          }));
        });
        setChanged(true);
      }
    });
    reader.readAsBinaryString(files![0]);
  };
  if (loading) {
    return <CircularProgress disableShrink />;
  }
  if (!recipe.id) {
    return <div>Recipe not found</div>;
  }
  const { ingredients, instructions } = recipe;
  const ingredientsList = ingredients.map((ingredient: Ingredient) => {
    const { amount, unit, name } = ingredient;
    return (
      <IngredientForm
        key={ingredient.id}
        id={ingredient.id}
        initialValue={`${amount === 0 ? "" : amount} ${unit === "" ? "" : `${unit} `}${name}`}
        multiline={false}
        tooltip=""
        onChange={(value: string, changeId: string) => {
          console.log(value, changeId);
          const updated = ingredients.map((ing: Ingredient) => {
            if (ing.id === changeId) {
              const { amount: a, name: n } = parseIngredient(value);
              return {
                id: changeId,
                unit: "",
                name: n,
                amount: a,
              };
            }
            return ing;
          });
          handleChangeInRecipe("ingredients", updated);
        }}
        onDelete={(deleteId: string) => {
          const updated = ingredients.filter((ing: Ingredient) => {
            return ing.id !== deleteId;
          });
          handleChangeInRecipe("ingredients", updated);
        }}
      />
    );
  });
  const instructionList = instructions.map(({ id: instructionId, text }, idx: number) => {
    return (
      <InstructionForm
        key={instructionId}
        id={instructionId}
        idx={idx}
        initialValue={text}
        onChange={(newText: string) => {
          const updated = instructions.map((instruction) => ({ ...instruction }));
          updated[idx] = { ...updated[idx], text: newText };
          handleChangeInRecipe("instructions", updated);
        }}
        onDelete={() => {
          const deleted = [...instructions].filter((_, i) => i !== idx);
          handleChangeInRecipe("instructions", deleted);
        }}
      />
    );
  });
  return (
    <Grid container spacing={0}>
      {/* <Prompt
          when={formChanged}
          message="You have unsaved changes. Your changes will not be saved if you leave this page!"
        /> */}
      <Hidden mdDown>
        <Grid item xs={12} lg={6}>
          <RecipeComponent editView recipe={recipe} />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={6} className={classes.leftPart}>
        <Paper className="paper" elevation={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <TextField
                variant="outlined"
                fullWidth
                required
                label="Recipe Title"
                value={recipe.name}
                onChange={(e) => {
                  handleChangeInRecipe("name", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <label htmlFor="upload-photo">
                <IconButton color="primary" aria-label="upload picture" component="span">
                  <PhotoCamera />
                </IconButton>
                <input
                  style={{
                    display: "none",
                  }}
                  type="file"
                  id="upload-photo"
                  name="upload-photo"
                  accept="image/jpeg, image/png, image/bmp"
                  onChange={(event) => {
                    handleImageUpload(event);
                  }}
                />
              </label>
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                label="Description"
                aria-label="description"
                placeholder="Enter description of the recipe"
                value={recipe.description}
                onChange={(e) => {
                  handleChangeInRecipe("description", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                label="Original Url"
                aria-label="original-url"
                placeholder="Enter the original url of the recipe if there is any"
                value={recipe.originalUrl || ""}
                onChange={(e) => {
                  handleChangeInRecipe("originalUrl", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                value={recipe.timeMinutes}
                label="Cooking Time"
                name="timeMinutes"
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  handleChangeInRecipe("timeMinutes", value);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="numberOfPortions">Portions</InputLabel>
                <Select
                  native
                  id="numberOfPortions"
                  value={recipe.numberOfPortions}
                  name="numberOfPortions"
                  label="Portions"
                  onChange={(e) => {
                    const value = parseInt(e.target.value as string, 10) || 0;
                    handleChangeInRecipe("numberOfPortions", value);
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={2}>2</option>
                  <option value={4}>4</option>
                  <option value={6}>6</option>
                  <option value={8}>8</option>
                  <option value={10}>10</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="mealType">Meal Type</InputLabel>
                <Select
                  native
                  id="mealType"
                  value={recipe.category}
                  name="category"
                  label="Meal Type"
                  onChange={(e) => {
                    handleChangeInRecipe("category", parseInt(e.target.value as string, 10));
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={CATEGORY.breakfast}>Breakfast</option>
                  <option value={CATEGORY.lunch}>Lunch</option>
                  <option value={CATEGORY.dinner}>Dinner</option>
                  <option value={CATEGORY.snack}>Snack</option>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              lg={12}
              container
              alignItems="center"
              onClick={() => {
                setExpandIngredients((prev) => !prev);
              }}
            >
              {expandIngredients ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              <Typography color="primary" variant="h5">
                Ingredients
              </Typography>
            </Grid>

            <IngredientForm
              multiline
              tooltip="You can add a single ingredient or multiple ingredients at once by separating them with line break"
              initialValue=""
              id={nextId()}
              onAdd={(value: string) => {
                const lines = value.split("\n");
                const updatedIngredients = [...ingredients];
                lines.forEach((line) => {
                  if (line !== "") {
                    const { amount, name } = parseIngredient(line);
                    updatedIngredients.push({
                      id: nextId(),
                      unit: "",
                      amount,
                      name,
                    });
                  }
                });
                handleChangeInRecipe("ingredients", updatedIngredients);
              }}
            />
            {expandIngredients ? ingredientsList : null}
            <Grid
              item
              container
              xs={12}
              lg={12}
              alignItems="center"
              onClick={() => {
                setExpandInstructions((prev) => !prev);
              }}
            >
              {expandInstructions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              <Typography color="primary" variant="h5">
                Instruction
              </Typography>
            </Grid>
            <InstructionForm
              id={nextId()}
              idx={instructions.length}
              initialValue=""
              onAdd={(step: string) => {
                handleChangeInRecipe("instructions", [...instructions, { id: nextId(), text: step }]);
              }}
            />
            {expandInstructions ? instructionList : null}
            <Grid item xs={12} lg={12} container justifyContent="center">
              <Button
                disabled={!changed}
                color="primary"
                variant="contained"
                onClick={() => {
                  updateRecipe(recipe);
                  setChanged(false);
                }}
              >
                Save Recipe
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

const style = (theme: Theme) =>
  ({
    leftPart: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "56px",
      },
    },
  } as const);

const mapStateToProps = (state: RootState) => ({
  recipeState: state.recipeState,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateRecipe: (recipe: Recipe) => dispatch(updateRecipeRequest(recipe, dispatch)),
  createImage: <F extends (image: { id: number; url: string }) => void>(
    format: string,
    base64Data: string,
    callback: F
  ) => dispatch(createImageRequest(format, base64Data, callback)),
  updateImage: <F extends () => void>(id: number, format: string, base64Data: string, callback: F) =>
    dispatch(updateImageRequest(id, format, base64Data, callback)),
  getRecipes: () => dispatch(getRecipesRequest()),
  setModalProps: (props: ModalProps) => dispatch(setModalPropsAction(props)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(RecipeForm));
