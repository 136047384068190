export const units = ["l", "dl", "cl", "ml", "kg", "hg", "g", "Tsp", "tsp"];
export type Units = "l" | "dl" | "cl" | "ml" | "kg" | "hg" | "g";

export enum CATEGORY {
  breakfast,
  lunch,
  dinner,
  snack,
}
export interface Ingredient {
  id: string;
  name: string;
  amount: number;
  unit: string;
}
export interface Recipe {
  id?: number;
  name: string;
  description: string;
  originalUrl?: string;
  ingredients: Ingredient[];
  timeMinutes: number;
  numberOfPortions: number;
  category: CATEGORY;
  instructions: { id: string; text: string }[];
  imageId?: number;
  imageUrl?: string;
}
export interface ShoppingList {
  id?: number;
  name: string;
  items: Item[];
}
export interface Item {
  id?: number;
  name: string;
  amount: number;
  unit: string;
  checked: boolean;
  shoppingListId?: number;
}
