import { Dispatch } from "redux";
import { NavigateFunction } from "react-router-dom";
import URLS from "../config";
import {
  type CreateRecipeAction,
  type UpdateRecipeAction,
  type CreateRecipeRequest,
  type UpdateRecipeRequest,
  type DeleteRecipeAction,
  type DeleteRecipeRequest,
  type GetRecipesAction,
  type GetRecipesRequest,
  type RecipeAPIEndAction,
  type RecipeAPIStartAction,
  type CreateImageAction,
  type CreateImageRequest,
  type UpdateImageAction,
  type UpdateImageRequest,
} from "./Actions";
import { RecipeActionTypes, APIActionType } from "./ActionTypes";
import { type Recipe } from "../types";
import { type RecipeRecord } from "../types/DatabaseRecords";
import { convertRecipeToRecipeRecord } from "../utilities/helpers";
import { setAlertPropsAction } from "./GlobalActionCreator";
import { getErrorAlertProps, getSuccesAlertProps } from "../components/Alert";

const url = URLS.database;

// action creators
export function recipeAPIStartAction(): RecipeAPIStartAction {
  return {
    type: RecipeActionTypes.RECIPE_API_START,
  };
}

export function recipeAPIEndAction(): RecipeAPIEndAction {
  return {
    type: RecipeActionTypes.RECIPE_API_END,
  };
}

export function createRecipeAction(recipe: Recipe): CreateRecipeAction {
  return {
    type: RecipeActionTypes.CREATE_RECIPE,
    recipe,
  };
}
export function createRecipeRequest(
  recipe: Recipe,
  dispatch: Dispatch,
  navigate: NavigateFunction
): CreateRecipeRequest {
  const data = convertRecipeToRecipeRecord(recipe);
  return {
    type: APIActionType.API,
    payload: {
      url: `${url}/recipe`,
      method: "POST",
      apiStart: (): RecipeAPIStartAction => recipeAPIStartAction(),
      apiEnd: (): RecipeAPIEndAction => recipeAPIEndAction(),
      onSuccess: (result: Recipe): CreateRecipeAction => {
        const action = createRecipeAction(recipe);
        dispatch(setAlertPropsAction(getSuccesAlertProps("Recipe created")));
        navigate(`/recipe/${result.id}/edit`);
        return action;
      },
      onFailure: (error: Error) => setAlertPropsAction(getErrorAlertProps(error.message)),
      label: RecipeActionTypes.CREATE_RECIPE,
      data,
    },
  };
}

export function updateRecipeAction(recipe: Recipe): UpdateRecipeAction {
  return {
    type: RecipeActionTypes.UPDATE_RECIPE,
    recipe,
  };
}

export function updateRecipeRequest(recipe: Recipe, dispatch: Dispatch): UpdateRecipeRequest {
  const data = convertRecipeToRecipeRecord(recipe);
  return {
    type: APIActionType.API,
    payload: {
      url: `${url}/recipe/${recipe.id}`,
      method: "PUT",
      apiStart: (): RecipeAPIStartAction => recipeAPIStartAction(),
      apiEnd: (): RecipeAPIEndAction => recipeAPIEndAction(),
      onSuccess: (): UpdateRecipeAction => {
        dispatch(setAlertPropsAction(getSuccesAlertProps("Saved")));
        return updateRecipeAction(recipe);
      },
      onFailure: (error: Error) => setAlertPropsAction(getErrorAlertProps(error.message)),
      label: RecipeActionTypes.UPDATE_RECIPE,
      data,
    },
  };
}

export function deleteRecipeAction(id: number): DeleteRecipeAction {
  return {
    type: RecipeActionTypes.DELETE_RECIPE,
    id,
  };
}

export function deleteRecipeRequest(id: number, dispatch: Dispatch): DeleteRecipeRequest {
  return {
    type: APIActionType.API,
    payload: {
      url: `${url}/recipe/${id}`,
      method: "DELETE",
      apiStart: (): RecipeAPIStartAction => recipeAPIStartAction(),
      apiEnd: (): RecipeAPIEndAction => recipeAPIEndAction(),
      onSuccess: () => {
        dispatch(setAlertPropsAction(getSuccesAlertProps("Recipe deleted")));
        return deleteRecipeAction(id);
      },
      onFailure: (error: Error) => setAlertPropsAction(getErrorAlertProps(error.message)),
      label: RecipeActionTypes.DELETE_RECIPE,
      data: {},
    },
  };
}

export function getRecipesAction(list: RecipeRecord[]): GetRecipesAction {
  return {
    type: RecipeActionTypes.GET_RECIPES,
    list,
  };
}

export function getRecipesRequest(): GetRecipesRequest {
  return {
    type: APIActionType.API,
    payload: {
      url: `${url}/recipe`,
      method: "GET",
      apiStart: (): RecipeAPIStartAction => recipeAPIStartAction(),
      apiEnd: (): RecipeAPIEndAction => recipeAPIEndAction(),
      onSuccess: (list: [RecipeRecord]): GetRecipesAction => getRecipesAction(list),
      onFailure: (error: Error) => setAlertPropsAction(getErrorAlertProps(error.message)),
      label: RecipeActionTypes.GET_RECIPES,
      data: "",
    },
  };
}

export function createImageAction(): CreateImageAction {
  return {
    type: RecipeActionTypes.CREATE_IMAGE,
  };
}
export function createImageRequest<F extends (image: { id: number; url: string }) => void>(
  format: string,
  base64Data: string,
  callback: F
): CreateImageRequest {
  return {
    type: APIActionType.API,
    payload: {
      url: `${url}/image`,
      method: "POST",
      apiStart: (): RecipeAPIStartAction => recipeAPIStartAction(),
      apiEnd: (): RecipeAPIEndAction => recipeAPIEndAction(),
      onSuccess: (image: { id: number; url: string }): CreateImageAction => {
        callback(image);
        return createImageAction();
      },
      onFailure: (error: Error) => setAlertPropsAction(getErrorAlertProps(error.message)),
      data: {
        format,
        base64Data,
      },
    },
  };
}

export function updateImageAction(): UpdateImageAction {
  return {
    type: RecipeActionTypes.UPDATE_IMAGE,
  };
}
export function updateImageRequest<F extends () => void>(
  id: number,
  format: string,
  base64Data: string,
  callback: F
): UpdateImageRequest {
  return {
    type: APIActionType.API,
    payload: {
      url: `${url}/image/${id}`,
      method: "PUT",
      apiStart: (): RecipeAPIStartAction => recipeAPIStartAction(),
      apiEnd: (): RecipeAPIEndAction => recipeAPIEndAction(),
      onSuccess: (): UpdateImageAction => {
        callback();
        return updateImageAction();
      },
      onFailure: (error: Error) => setAlertPropsAction(getErrorAlertProps(error.message)),
      data: {
        format,
        base64Data,
      },
    },
  };
}
