import { type CustomAlertProps, type ModalProps, type SetAlertPropsAction, type SetModalPropsAction } from "./Actions";
import { GlobalActionType } from "./ActionTypes";

export function setModalPropsAction(props: ModalProps): SetModalPropsAction {
  return {
    type: GlobalActionType.SET_MODAL_PROPS,
    props,
  };
}

export function setAlertPropsAction(props: CustomAlertProps): SetAlertPropsAction {
  return {
    type: GlobalActionType.SET_ALERT_PROPS,
    props,
  };
}
