import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes, Navigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { type Dispatch } from "redux";
import ShoppingListsPage from "./pages/ShoppingListsPage";
import ProtectedRoute from "./components/ProtectedRoute";
import { refreshTokenRequest } from "./actions/AuthActionCreators";
import RecipesPage from "./pages/RecipesPage";
import EditRecipeForm from "./components/RecipeForm";

import { type RootState } from "./reducers";
import { type AuthState } from "./reducers/AuthenticationReducers";
import RecipePage from "./pages/RecipePage";
import { getRecipesRequest } from "./actions/RecipeActionCreators";
import NavigationComponent from "./components/NavigationComponent";

// Google analytic hook
const trackingId = "UA-179098823-1";
declare const gtag: (type: string, trackingId: string, options: Record<string, any>) => void;
gtag("config", trackingId, {
  send_page_view: false,
});
// history.listen((a) => {
//   console.log(a);
//   gtag("event", "page_view", {
//     page_path: location.pathname,
//   });
// });
interface Props {
  authState: AuthState;
  refreshToken: () => void;
  getRecipes: () => void;
}

function App({ refreshToken, authState: { isAuthenticated }, getRecipes }: Props) {
  useEffect(() => {
    if (!isAuthenticated) {
      refreshToken();
    }
  }, [isAuthenticated, refreshToken]);
  return (
    <>
      <NavigationComponent />
      <Routes>
        <Route path="/" element={<Navigate to="/shoppinglists" />} />
        <Route
          path="/shoppinglists"
          element={
            <ProtectedRoute>
              <ShoppingListsPage getRecipes={getRecipes} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/recipes"
          element={
            <ProtectedRoute>
              <RecipesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="recipe/:id/edit"
          element={
            <ProtectedRoute>
              <EditRecipeForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="recipe/:id"
          element={
            <ProtectedRoute>
              <RecipePage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}
const mapStateToProps = (state: RootState) => ({
  authState: state.authState,
  recipeState: state.recipeState,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  refreshToken: () => dispatch(refreshTokenRequest()),
  getRecipes: () => dispatch(getRecipesRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
