import nextId from "react-id-generator";
import { type Recipe } from "../types";
import { type RecipeRecord } from "../types/DatabaseRecords";

export const convertRecipeToRecipeRecord = (recipe: Recipe): RecipeRecord => {
  const { ingredients, instructions } = recipe;
  return {
    ...recipe,
    ingredients: ingredients.map(({ id, ...i }) => i),
    instructions: instructions.map(({ text }) => text),
  };
};
export const convertRecipeRecordToRecipe = (recipe: RecipeRecord): Recipe => {
  const { ingredients, instructions } = recipe;
  return {
    ...recipe,
    ingredients: ingredients.map((i) => ({
      id: nextId(),
      ...i,
    })),
    instructions: instructions.map((i) => ({
      id: nextId(),
      text: i,
    })),
  };
};
export const parseIngredient = (str: string) => {
  const s = str.trim();
  const amountMatch = s.match(/^[0-9]+/);
  let name = s;
  if (amountMatch != null) {
    name = s.slice(amountMatch[0].length).trim();
  }
  const amount = amountMatch != null ? Number(amountMatch[0]) : 0;
  return {
    amount,
    name,
  };
};

export const renderIngredient = (amount: number, unit: string, name: string) => {
  return `${amount === 0 ? "" : amount} ${unit === "" ? "" : `${unit} `}${name}`;
};

export const filterRecipes = (recipes: [string, Recipe][], filter: string) => {
  const filteredRecipes = filter
    ? recipes.filter((value) => {
        const [id, recipe] = value;
        if (recipe.name.toLocaleLowerCase().includes(filter)) {
          return true;
        }
        if (recipe.description.toLocaleLowerCase().includes(filter)) {
          return true;
        }
        if (recipe.ingredients.some((ingredient) => ingredient.name.toLocaleLowerCase().includes(filter))) {
          return true;
        }
        if (recipe.instructions.some((instruction) => instruction.text.toLocaleLowerCase().includes(filter))) {
          return true;
        }
        return false;
      })
    : recipes;

  return filteredRecipes;
};
