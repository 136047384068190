import React, { useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress, Container, Paper, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { type RootState } from "../reducers";
import { type RecipeState } from "../reducers/RecipeReducers";
import "./RecipePage.css";
import RecipeComponent from "../components/RecipeComponent";
import { getRecipesRequest } from "../actions/RecipeActionCreators";

interface Props {
  recipeState: RecipeState;
  getRecipes: () => void;
}

function RecipePage({ recipeState: { isLoading, recipes }, getRecipes }: Props) {
  const { id } = useParams();
  useEffect(() => {
    if (isLoading) {
      getRecipes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const recipe = recipes[id ? parseInt(id, 10) : -1];
  return (
    <Container>
      {isLoading && (
        <Paper elevation={2} className="paper">
          <CircularProgress disableShrink />
        </Paper>
      )}
      {!recipe ? (
        <Paper elevation={2} className="paper">
          <Typography color="primary" variant="h6" align="center">
            Recipe not found
          </Typography>
        </Paper>
      ) : (
        <RecipeComponent editView={false} recipe={recipe} />
      )}
    </Container>
  );
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getRecipes: () => dispatch(getRecipesRequest()),
});
const mapStateToProps = (state: RootState) => ({
  recipeState: state.recipeState,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipePage);
