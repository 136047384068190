import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { CircularProgress } from "@material-ui/core";
import { refreshTokenRequest } from "../actions/AuthActionCreators";
import LoginModal from "./LoginModal";
import { type RootState } from "../reducers";
import { type AuthState } from "../reducers/AuthenticationReducers";

interface Props {
  authState: AuthState;
  refreshToken: () => void;
}

function ProtectedRoute({
  authState: { isAuthenticated, isAuthenticating },
  refreshToken,
  children,
}: React.PropsWithChildren<Props>) {
  const [showLoginModal, setShowLoginModal] = useState(!isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated && !showLoginModal) {
      setShowLoginModal(true);
    } else if (!isAuthenticated) {
      refreshToken();
    }
  }, [isAuthenticated, refreshToken, showLoginModal]);

  if (isAuthenticating) {
    return <CircularProgress disableShrink />;
  }

  if (isAuthenticated && children) {
    if (children) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{children}</>;
    }
    return null;
  }
  return (
    <LoginModal
      show={showLoginModal}
      onHide={() => {
        setShowLoginModal(false);
      }}
    />
  );
}

const mapStateToProps = (state: RootState) => ({
  authState: state.authState,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  refreshToken: () => dispatch(refreshTokenRequest()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
