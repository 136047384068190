import React, { type FormEvent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { Button, Container, Grid, Paper, TextField, useMediaQuery } from "@material-ui/core";
import ShoppingListComponent from "../components/ShoppingListComponent";
import { type ShoppingListState } from "../reducers/ShoppingListReducers";
import { createShoppingListRequest, getShoppingListsRequest } from "../actions/ShoppingListActionCreators";
import { type RootState } from "../reducers";
import ImportItemModal from "../components/ImportItemsModal";

interface Props {
  shoppingListState: ShoppingListState;
  getShoppingLists: () => void;
  createShoppingList: (listName: string) => void;
  getRecipes: () => void;
}
interface State {
  open: boolean;
  newListInput: string;
}
const initialState: Readonly<State> = {
  open: false,
  newListInput: "",
};
function ShoppingListsPage(props: Props) {
  const smallScreen = useMediaQuery("(max-width:960px)");
  const [state, setState] = useState<State>(initialState);
  const { shoppingListState, getShoppingLists, createShoppingList, getRecipes } = props;
  useEffect(() => {
    if (shoppingListState.isLoading) {
      getShoppingLists();
    }
  }, [shoppingListState.isLoading, getShoppingLists]);
  const addShoppingList = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (state.newListInput !== "") {
      createShoppingList(state.newListInput);
    }
    setState({
      ...state,
      newListInput: "",
    });
  };
  const lists = shoppingListState.shoppingLists.map((list) => {
    return <ShoppingListComponent key={list.id} id={list.id!} listName={list.name} items={list.items} />;
  });
  return (
    <Container>
      <Paper elevation={smallScreen ? 0 : 2} className="paper">
        <Grid container justifyContent="center" spacing={1}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            item
            xs={12}
          >
            <Button
              color="primary"
              variant="outlined"
              size="medium"
              onClick={() => {
                setState({
                  ...state,
                  open: true,
                });
              }}
            >
              Import from recipe
            </Button>
          </Grid>
          <Grid item xs={12}>
            <form
              onSubmit={(e) => {
                addShoppingList(e);
              }}
            >
              <TextField
                label="List name"
                variant="outlined"
                fullWidth
                value={state.newListInput}
                size="small"
                onChange={(e) => {
                  setState({
                    ...state,
                    newListInput: e.target.value,
                  });
                }}
              />
            </form>
          </Grid>
          <Grid item xs={12}>
            {lists}
          </Grid>
        </Grid>
        <ImportItemModal
          onClose={() => {
            setState({
              ...state,
              open: false,
            });
          }}
          getRecipes={getRecipes}
          open={state.open}
        />
      </Paper>
    </Container>
  );
}
const mapStateToProps = (state: RootState) => ({
  shoppingListState: state.shoppingListState,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getShoppingLists: () => dispatch(getShoppingListsRequest()),
  createShoppingList: (listName: string) => {
    dispatch(createShoppingListRequest(dispatch, listName));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ShoppingListsPage);
