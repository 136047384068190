import { type AuthAction } from "../actions/Actions";
import { AuthActionTypes as actionType } from "../actions/ActionTypes";

export interface AuthState {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isAuthenticating: true,
};

export const authState = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AuthState = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case actionType.AUTH_START:
      return {
        ...state,
        isAuthenticating: true,
      };

    case actionType.AUTH_END:
      return {
        ...state,
        isAuthenticating: false,
      };

    case actionType.REFRESH_TOKEN:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };

    case actionType.SIGNED_IN:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };

    case actionType.SIGN_OUT:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };

    default:
      return state;
  }
};
