import { type CustomAlertProps, type GlobalAction, type ModalProps } from "../actions/Actions";
import { GlobalActionType as actionType } from "../actions/ActionTypes";

export interface GlobalState {
  modalProps: ModalProps;
  alertProps: CustomAlertProps;
}

const initialState: GlobalState = {
  modalProps: {
    open: false,
    title: "",
    msg: "",
    onClose: undefined,
    btn1Text: "",
    btn1Color: undefined,
    btn1OnClick: undefined,
    btn2Text: "",
    btn2Color: undefined,
    btn2OnClick: undefined,
  },
  alertProps: {
    open: false,
    msg: "",
    alertType: "info",
  },
};

export const globalState = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: GlobalState = initialState,
  action: GlobalAction
): GlobalState => {
  switch (action.type) {
    case actionType.SET_MODAL_PROPS:
      return {
        ...state,
        modalProps: action.props,
      };
    case actionType.SET_ALERT_PROPS:
      return {
        ...state,
        alertProps: action.props,
      };
    default:
      return state;
  }
};
