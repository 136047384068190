import { Grid, TextField, IconButton, makeStyles, Button } from "@material-ui/core";
import React, { useState } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import nextId from "react-id-generator";

interface Props {
  id?: string;
  idx: number;
  onAdd?: (text: string) => void;
  onChange?: (text: string) => void;
  onDelete?: (idx: number, id: string) => void;
  initialValue: string;
}
const style = makeStyles(() => ({
  button: {
    position: "absolute",
    right: "5px",
    top: "-15px",
    zIndex: 2,
  },
  textField: {
    flexGrow: 1,
    marginRight: "9px",
  },
}));
export default function InstructionForm(props: Props) {
  const { id: instructionId, idx, onAdd, onChange, onDelete, initialValue } = props;
  const [text, setText] = useState<string>(initialValue);
  const classes = style();
  let id = instructionId || nextId();
  const clearState = () => {
    setText("");
    id = nextId();
  };
  return (
    <Grid
      key={id}
      item
      container
      justifyContent="space-between"
      style={{
        position: "relative",
      }}
    >
      <TextField
        className={classes.textField}
        variant="outlined"
        multiline
        label={`Step ${idx + 1}`}
        aria-label="instruction"
        placeholder="Enter instruction"
        value={text}
        size="small"
        name="instruction"
        onChange={(
          e: React.ChangeEvent<{
            value: string;
          }>
        ) => {
          setText(e.target.value);
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />
      {onAdd != null ? (
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => {
            if (text !== "") {
              onAdd(text);
              clearState();
            }
          }}
        >
          Add
        </Button>
      ) : null}
      {onDelete && (
        <IconButton
          className={classes.button}
          color="primary"
          size="medium"
          edge="end"
          onClick={() => onDelete(idx, text)}
        >
          <CancelIcon />
        </IconButton>
      )}
    </Grid>
  );
}

InstructionForm.defaultProps = {
  id: undefined,
  onAdd: undefined,
  onChange: undefined,
  onDelete: undefined,
};
