import React, { type FormEvent, useState } from "react";
import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { TextField, ListItem } from "@material-ui/core";
import { updateShoppingListRequest } from "../actions/ShoppingListActionCreators";
import { type Item } from "../types";
import { parseIngredient } from "../utilities/helpers";

interface Props {
  shoppingListId: number;
  listName: string;
  items: Item[];
  addItemRequest: (id: number, name: string, items: Item[]) => void;
}
function ItemForm(props: Props) {
  const [itemString, setItemString] = useState("");
  const { items, shoppingListId, addItemRequest, listName } = props;
  const addItem = (event: FormEvent<HTMLFormElement>) => {
    const { amount, name } = parseIngredient(itemString);
    event.preventDefault();
    if (itemString !== "") {
      addItemRequest(shoppingListId, listName, [
        ...items,
        {
          shoppingListId,
          amount,
          name,
          unit: "",
          checked: false,
        },
      ]);
      setItemString("");
    }
  };
  return (
    <ListItem disableGutters>
      <form
        onSubmit={(e) => {
          addItem(e);
        }}
        style={{
          width: "100%",
        }}
      >
        <TextField
          fullWidth
          label="Add item"
          variant="outlined"
          size="small"
          value={itemString}
          onChange={(e) => {
            setItemString(e.target.value);
          }}
        />
      </form>
    </ListItem>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addItemRequest: (id: number, name: string, items: Item[]) => dispatch(updateShoppingListRequest(id, name, items)),
});

export default connect(null, mapDispatchToProps)(ItemForm);
