import jwtDecode from "jwt-decode";
import { type Middleware, type MiddlewareAPI, type Dispatch } from "redux";
import { refreshTokenRequest, authStart, authEnd } from "../actions/AuthActionCreators";
import { type AuthRequest } from "../actions/Actions";
import { AuthActionTypes } from "../actions/ActionTypes";

const authMiddleware: Middleware = (api: MiddlewareAPI) => (next: Dispatch<AuthRequest>) => (action: AuthRequest) => {
  next(action);
  if (action.type !== "AUTH") return;
  api.dispatch(authStart());
  const { url, method, onSuccess, onFailure, label } = action.payload;
  const xhr = new XMLHttpRequest();
  xhr.open("POST", url);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.withCredentials = true;

  xhr.onload = () => {
    if (xhr.status === 401) {
      if (label !== AuthActionTypes.REFRESH_TOKEN) {
        api.dispatch(onFailure(new Error("Unauthorized Action")));
      }
      api.dispatch(authEnd());
    } else {
      try {
        if (label === "SIGN_IN" || label === "REFRESH_TOKEN") {
          const claims: any = jwtDecode(xhr.responseText);
          const expiration: Date = new Date(claims.exp * 1000);
          setTimeout(() => {
            api.dispatch(refreshTokenRequest());
          }, expiration.getTime() - new Date().getTime() - 60 * 1000);
          if (label === "SIGN_IN") {
            if (window.gapi.auth2) {
              const auth2 = window.gapi.auth2.getAuthInstance();
              auth2.signOut().then(() => {
                console.log("User signed out.");
              });
            }
          }
        }
        api.dispatch(onSuccess());
        api.dispatch(authEnd());
      } catch (error) {
        api.dispatch(authEnd());
        api.dispatch(onFailure(error as Error));
      }
    }
  };
  if (action.payload.label === AuthActionTypes.SIGNED_IN) {
    const body = JSON.stringify({
      method,
      token: action.payload.token,
    });
    xhr.send(body);
  } else {
    xhr.send();
  }

  //     .catch(error => {
  //       dispatch(apiError(error));
  //       dispatch(onFailure(error));

  //       if (error.response && error.response.status === 403) {
  //         dispatch(accessDenied(window.location.pathname));
  //       }
  //     })
  //    .finally(() => {
  //       if (label) {
  //         dispatch(apiEnd(label));
  //       }

  // .catch((error) => {
  //   //   dispatch(apiError(error));
  //   //   dispatch(onFailure(error));
  //   console.log("Error");
  //   if (error.response && error.response.status === 403) {
  //     // dispatch(accessDenied(window.location.pathname));
  //     console.log("access Denied");
  //   }
  // });
};
export default authMiddleware;
