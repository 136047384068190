import { type ShoppingListAction } from "../actions/Actions";
import { ShoppingListActionType as actionType } from "../actions/ActionTypes";
import { type Item, type ShoppingList } from "../types";

export interface ShoppingListState {
  shoppingLists: ShoppingList[];
  isLoading: boolean;
}
const initialState = {
  shoppingLists: new Array<ShoppingList>(),
  isLoading: true,
};

export const shoppingListState = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ShoppingListState = initialState,
  action: ShoppingListAction
): ShoppingListState => {
  switch (action.type) {
    case actionType.SHOPPING_LIST_API_START:
      return {
        ...state,
        isLoading: true,
      };

    case actionType.SHOPPING_LIST_API_END:
      return {
        ...state,
        isLoading: false,
      };

    case actionType.GET_SHOPPING_LIST:
      return {
        ...state,
        shoppingLists: action.list,
      };

    case actionType.CREATE_SHOPPING_LIST:
      return {
        ...state,
        shoppingLists: [...state.shoppingLists, action.list],
      };

    case actionType.UPDATE_SHOPPING_LIST: {
      const lists = state.shoppingLists.map((list: ShoppingList) => {
        if (list.id === action.list.id) {
          return action.list;
        }
        return list;
      });
      return {
        ...state,
        shoppingLists: lists,
      };
    }
    case actionType.DELETE_SHOPPING_LIST: {
      return {
        ...state,
        shoppingLists: state.shoppingLists.filter((list) => list.id !== action.listID),
      };
    }

    case actionType.UPDATE_ITEM: {
      const updated = state.shoppingLists.map((list: ShoppingList) => {
        if (list.id === action.item.shoppingListId) {
          return {
            ...list,
            items: list.items.map((item: Item) => {
              if (item.id === action.item.id) {
                return action.item;
              }
              return item;
            }),
          };
        }
        return list;
      });
      return {
        ...state,
        shoppingLists: updated,
      };
    }

    case actionType.DELETE_ITEM: {
      const deleted = state.shoppingLists.map((list: ShoppingList) => {
        if (list.id === action.listId) {
          return {
            ...list,
            items: list.items.filter((item: Item) => item.id !== action.itemId),
          };
        }
        return list;
      });
      return {
        ...state,
        shoppingLists: deleted,
      };
    }
    default:
      return state;
  }
};
