import axios from "axios";
import { type Middleware, type MiddlewareAPI, type Dispatch } from "redux";
import { type ApiRequest } from "../actions/Actions";

const apiMiddleware: Middleware =
  (api: MiddlewareAPI) =>
  (next: Dispatch<ApiRequest>) =>
  (action: ApiRequest): void => {
    next(action);
    if (action.type !== "API") return;
    const {
      url,
      method,
      onSuccess,
      apiStart,
      apiEnd,
      onFailure,
      data,
      // label,
    } = action.payload;
    //   const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

    // axios default configs
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    //   if (label) {
    api.dispatch(apiStart());
    //   }
    axios
      .request({
        url,
        method,
        data,
        withCredentials: true,
      })
      .then(({ data: responseData }) => {
        api.dispatch(onSuccess(responseData));
        api.dispatch(apiEnd());
      })
      .catch((error: Error) => {
        api.dispatch(onFailure(error));
        api.dispatch(apiEnd());

        // if (error.response && error.response.status === 403) {
        //   dispatch(accessDenied(window.location.pathname));
        // }
      })
      .finally(() => {});
  };
export default apiMiddleware;
