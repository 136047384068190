import { Dispatch } from "redux";
import URLS from "../config";
import {
  type CreateShoppingListAction,
  type CreateShoppingListRequest,
  type UpdateShoppingListAction,
  type UpdateShoppingListRequest,
  type DeleteShoppingListsAction,
  type GetShoppingListsAction,
  type GetShoppingListsRequest,
  type UpdateItemAction,
  type UpdateItemRequest,
  type DeleteItemAction,
  type DeleteItemRequest,
  type ShoppingListAPIStartAction,
  type ShoppingListAPIEndAction,
} from "./Actions";
import { ShoppingListActionType, APIActionType } from "./ActionTypes";
import { type ShoppingList, type Item } from "../types";
import { setAlertPropsAction } from "./GlobalActionCreator";

import { getErrorAlertProps, getSuccesAlertProps } from "../components/Alert";

export function shoppingListAPIStartAction(): ShoppingListAPIStartAction {
  return {
    type: ShoppingListActionType.SHOPPING_LIST_API_START,
  };
}

export function shoppingListAPIEndAction(): ShoppingListAPIEndAction {
  return {
    type: ShoppingListActionType.SHOPPING_LIST_API_END,
  };
}

const url = URLS.database;
// action creators
export function createShoppingListAction(list: ShoppingList): CreateShoppingListAction {
  return {
    type: ShoppingListActionType.CREATE_SHOPPING_LIST,
    list,
  };
}
export function createShoppingListRequest(
  dispatch: Dispatch,
  name: string,
  items: Item[] = []
): CreateShoppingListRequest {
  return {
    type: APIActionType.API,
    payload: {
      url: `${url}/shoppinglist`,
      method: "POST",
      apiStart: (): ShoppingListAPIStartAction => shoppingListAPIStartAction(),
      apiEnd: (): ShoppingListAPIEndAction => shoppingListAPIEndAction(),
      onSuccess: (list: ShoppingList): CreateShoppingListAction => {
        dispatch(setAlertPropsAction(getSuccesAlertProps("Shopping list created")));
        return createShoppingListAction(list);
      },
      onFailure: (error: Error) => {
        return setAlertPropsAction(getErrorAlertProps(error.message));
      },
      label: ShoppingListActionType.CREATE_SHOPPING_LIST,
      data: {
        name,
        items,
      },
    },
  };
}

export function updateShoppingListAction(list: ShoppingList): UpdateShoppingListAction {
  return {
    type: ShoppingListActionType.UPDATE_SHOPPING_LIST,
    list,
  };
}
export function updateShoppingListRequest(id: number, name: string, items: Item[]): UpdateShoppingListRequest {
  return {
    type: APIActionType.API,
    payload: {
      url: `${url}/shoppinglist/${id}`,
      method: "PUT",
      apiStart: (): ShoppingListAPIStartAction => shoppingListAPIStartAction(),
      apiEnd: (): ShoppingListAPIEndAction => shoppingListAPIEndAction(),
      onSuccess: (list: ShoppingList): UpdateShoppingListAction => updateShoppingListAction(list),
      onFailure: (error: Error) => setAlertPropsAction(getErrorAlertProps(error.message)),
      label: ShoppingListActionType.UPDATE_SHOPPING_LIST,
      data: {
        name,
        items,
      },
    },
  };
}

export function deleteShoppingListsAction(listID: number): DeleteShoppingListsAction {
  return {
    type: ShoppingListActionType.DELETE_SHOPPING_LIST,
    listID,
  };
}

export function deleteShoppingListRequest(listID: number, dispatch: Dispatch) {
  return {
    type: APIActionType.API,
    payload: {
      url: `${url}/shoppinglist/${listID}`,
      method: "DELETE",
      apiStart: (): ShoppingListAPIStartAction => shoppingListAPIStartAction(),
      apiEnd: (): ShoppingListAPIEndAction => shoppingListAPIEndAction(),
      onSuccess: (): DeleteShoppingListsAction => {
        dispatch(setAlertPropsAction(getSuccesAlertProps("Shopping list deleted")));
        return deleteShoppingListsAction(listID);
      },
      onFailure: (error: Error) => setAlertPropsAction(getErrorAlertProps(error.message)),
      label: ShoppingListActionType.DELETE_SHOPPING_LIST,
    },
  };
}

export function getShoppingListsAction(list: ShoppingList[]): GetShoppingListsAction {
  return {
    type: ShoppingListActionType.GET_SHOPPING_LIST,
    list,
  };
}

export function getShoppingListsRequest(): GetShoppingListsRequest {
  return {
    type: APIActionType.API,
    payload: {
      url: `${url}/shoppinglist`,
      method: "GET",
      apiStart: (): ShoppingListAPIStartAction => shoppingListAPIStartAction(),
      apiEnd: (): ShoppingListAPIEndAction => shoppingListAPIEndAction(),
      onSuccess: (list: ShoppingList[]): GetShoppingListsAction => getShoppingListsAction(list),
      onFailure: (error: Error) => setAlertPropsAction(getErrorAlertProps(error.message)),
      label: ShoppingListActionType.GET_SHOPPING_LIST,
      data: "",
    },
  };
}
export function updateItemAction(item: Item): UpdateItemAction {
  return {
    type: ShoppingListActionType.UPDATE_ITEM,
    item,
  };
}

export function updateItemRequest(item: Item): UpdateItemRequest {
  return {
    type: APIActionType.API,
    payload: {
      url: `${url}/shoppinglist/item/${item.id}`,
      method: "PUT",
      apiStart: (): ShoppingListAPIStartAction => shoppingListAPIStartAction(),
      apiEnd: (): ShoppingListAPIEndAction => shoppingListAPIEndAction(),
      onSuccess: (): UpdateItemAction => updateItemAction(item),
      onFailure: (error: Error) => setAlertPropsAction(getErrorAlertProps(error.message)),
      label: ShoppingListActionType.UPDATE_ITEM,
      data: {
        name: item.name,
        amount: item.amount,
        unit: item.unit,
        checked: item.checked,
      },
    },
  };
}

export function deleteItemAction(item: Item): DeleteItemAction {
  return {
    type: ShoppingListActionType.DELETE_ITEM,
    listId: item.shoppingListId!,
    itemId: item.id!,
  };
}

export function deleteItemRequest(item: Item): DeleteItemRequest {
  return {
    type: APIActionType.API,
    payload: {
      url: `${url}/shoppinglist/item/${item.id}`,
      method: "DELETE",
      apiStart: (): ShoppingListAPIStartAction => shoppingListAPIStartAction(),
      apiEnd: (): ShoppingListAPIEndAction => shoppingListAPIEndAction(),
      onSuccess: (): DeleteItemAction => deleteItemAction(item),
      onFailure: (error: Error) => setAlertPropsAction(getErrorAlertProps(error.message)),
      label: ShoppingListActionType.DELETE_ITEM,
      data: {},
    },
  };
}
