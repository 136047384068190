import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";

interface Props {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

function Search({ value, onChange, className = "" }: Props) {
  return (
    <TextField
      className={className}
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="secondary" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton color="secondary" onClick={() => onChange("")} edge="end">
              <Clear height={12} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onChange?.(event.target.value);
      }}
    />
  );
}

Search.defaultProps = {
  className: "",
};
export default Search;
