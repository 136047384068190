import React from "react";
import { Snackbar, makeStyles } from "@material-ui/core";
import MUIAlert, { type AlertProps } from "@mui/material/Alert";
import { type Dispatch } from "redux";
import { connect } from "react-redux";
import { type CustomAlertProps } from "../actions/Actions";
import { type RootState } from "../reducers";
import { setAlertPropsAction } from "../actions/GlobalActionCreator";

interface Props {
  alertProps: CustomAlertProps;
  setAlertProps: (props: CustomAlertProps) => void;
}

export const getErrorAlertProps = (msg: string): CustomAlertProps => ({
  open: true,
  msg,
  alertType: "error",
});

export const getSuccesAlertProps = (msg: string): CustomAlertProps => ({
  open: true,
  msg,
  alertType: "success",
});

function Alert(props: AlertProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MUIAlert elevation={6} variant="filled" {...props} />;
}

const useStyle = makeStyles(() => ({
  root: {
    bottom: "65px",
  },
}));
function CustomizedAlert(props: Props) {
  const { alertProps, setAlertProps } = props;
  const closeAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertProps({
      ...alertProps,
      open: false,
    });
  };
  const classes = useStyle();
  const { open, alertType, msg } = alertProps;
  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      className={classes.root}
      autoHideDuration={2000}
      onClose={closeAlert}
    >
      <Alert onClose={closeAlert} severity={alertType}>
        {msg}
      </Alert>
    </Snackbar>
  );
}

const mapStateToProps = (state: RootState) => ({
  alertProps: state.globalState.alertProps,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setAlertProps: (props: CustomAlertProps) => dispatch(setAlertPropsAction(props)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomizedAlert);
