import { combineReducers } from "redux";
import { shoppingListState, type ShoppingListState } from "./ShoppingListReducers";
import { authState, type AuthState } from "./AuthenticationReducers";
import { recipeState, type RecipeState } from "./RecipeReducers";
import { type GlobalState, globalState } from "./GlobalReducers";

export interface RootState {
  authState: AuthState;
  recipeState: RecipeState;
  shoppingListState: ShoppingListState;
  globalState: GlobalState;
}
const rootReducer = combineReducers({
  shoppingListState,
  authState,
  recipeState,
  globalState,
});
export default rootReducer;
