import { type RecipeAction } from "../actions/Actions";
import { RecipeActionTypes as actionType } from "../actions/ActionTypes";
import { type Recipe } from "../types";
import { convertRecipeRecordToRecipe } from "../utilities/helpers";

export interface RecipeState {
  recipes: Record<number, Recipe>;
  isLoading: boolean;
}
const initialState = {
  recipes: new Array<Recipe>(),
  isLoading: true,
};
export const recipeState = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: RecipeState = initialState,
  action: RecipeAction
): RecipeState => {
  switch (action.type) {
    case actionType.RECIPE_API_START:
      return {
        ...state,
        isLoading: true,
      };

    case actionType.RECIPE_API_END:
      return {
        ...state,
        isLoading: false,
      };

    case actionType.GET_RECIPES:
      return {
        ...state,
        recipes: action.list.reduce((accumulator, currentRecipe) => {
          const { id } = currentRecipe;
          if (!id) return accumulator;
          return {
            ...accumulator,
            [id]: convertRecipeRecordToRecipe(currentRecipe),
          };
        }, {}),
      };

    case actionType.CREATE_RECIPE: {
      const { id } = action.recipe;
      if (!id) return state;
      return {
        ...state,
        recipes: {
          ...state.recipes,
          [id]: action.recipe,
        },
      };
    }

    case actionType.UPDATE_RECIPE: {
      const { id } = action.recipe;
      if (id) {
        return {
          ...state,
          recipes: {
            ...state.recipes,
            [id]: action.recipe,
          },
        };
      }
      return state;
    }

    case actionType.DELETE_RECIPE: {
      const { [action.id]: recipeToRemoved, ...recipes } = state.recipes;
      return {
        ...state,
        recipes,
      };
    }
    default:
      return state;
  }
};
