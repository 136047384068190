import React from "react";
import { connect } from "react-redux";
import { type Dispatch } from "redux";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { type RootState } from "../reducers";
import { type ModalProps } from "../actions/Actions";
import { setModalPropsAction } from "../actions/GlobalActionCreator";

interface Props {
  modalProps: ModalProps;
  setModalProps: (props: ModalProps) => void;
}
const emptyModalProps: ModalProps = {
  open: false,
  title: "",
  msg: "",
  onClose: undefined,
  btn1Text: "",
  btn1Color: undefined,
  btn1OnClick: undefined,
  btn2Text: "",
  btn2Color: undefined,
  btn2OnClick: undefined,
};
function Modal(props: Props) {
  const closeModal = () => {
    const { setModalProps } = props;
    setModalProps(emptyModalProps);
  };
  const { modalProps } = props;
  const { open, title, msg, btn1Text, btn1Color, btn1OnClick, btn2Text, btn2Color, btn2OnClick } = modalProps;
  return (
    <Dialog
      onClose={() => {
        closeModal();
      }}
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{msg}</DialogContent>
      <DialogActions>
        {btn1Text && (
          <Button
            onClick={() => {
              if (btn1OnClick != null) {
                btn1OnClick();
              }
              closeModal();
            }}
            color={btn1Color || "default"}
            autoFocus
          >
            {btn1Text}
          </Button>
        )}
        {btn2Text && (
          <Button
            onClick={() => {
              if (btn2OnClick != null) {
                btn2OnClick();
              }
              closeModal();
            }}
            color={btn2Color || "primary"}
            autoFocus
          >
            {btn2Text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: RootState) => ({
  modalProps: state.globalState.modalProps,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModalProps: (props: ModalProps) => {
    dispatch(setModalPropsAction(props));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
