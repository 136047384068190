import React, { useState } from "react";
import ListIcon from "@material-ui/icons/List";
import DescriptionIcon from "@material-ui/icons/Description";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { connect } from "react-redux";
import { type Dispatch } from "redux";
import {
  MenuItem,
  BottomNavigation,
  BottomNavigationAction,
  withStyles,
  AppBar,
  Paper,
  Toolbar,
  Typography,
  Hidden,
  IconButton,
} from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import CustomizedAlert from "./Alert";
import { CATEGORY, Recipe } from "../types";
import Modal from "./Modal";
import { AuthState } from "../reducers/AuthenticationReducers";
import { createRecipeRequest, getRecipesRequest } from "../actions/RecipeActionCreators";
import { signOutRequest } from "../actions/AuthActionCreators";
import { RootState } from "../reducers";
import LoginModal from "./LoginModal";

interface Props {
  authState: AuthState;
  onSignOut: () => void;
  classes: Record<"header" | "root" | "bottomNav", string>;
  createRecipe: (recipe: Recipe, navigate: NavigateFunction) => void;
}

function NavigationComponent({ authState, onSignOut, classes, createRecipe }: Props) {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(location.pathname);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const openLoginModal = () => {
    setShowLoginModal(true);
  };
  const handleProfileMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeLoginModal = () => {
    setShowLoginModal(false);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const handleNavigationSelection = (newPath: string) => {
    setCurrentPath(newPath);
    navigate(newPath);
  };
  return (
    <Paper>
      <Modal key={0} />
      <CustomizedAlert />
      <AppBar position="static">
        <Toolbar className={classes.header}>
          <Button color="inherit" disableRipple>
            <Typography
              onClick={() => {
                handleNavigationSelection("/");
              }}
              variant="h6"
            >
              Meal Agent
            </Typography>
          </Button>
          <Hidden xsDown>
            <nav className="navbar">
              <Button
                color="inherit"
                onClick={() => {
                  handleNavigationSelection("/shoppinglists");
                }}
              >
                Shopping List
              </Button>
              <Button
                color="inherit"
                onClick={() => {
                  handleNavigationSelection("/recipes");
                }}
              >
                Recipes
              </Button>
            </nav>
          </Hidden>
          {!authState.isAuthenticating && authState.isAuthenticated && (
            <div>
              <IconButton color="inherit" onClick={handleProfileMenuClick}>
                <AccountCircle />
              </IconButton>
              <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
                <MenuItem onClick={closeMenu}>Profile</MenuItem>
                <MenuItem onClick={closeMenu}>Your recipes</MenuItem>
                <MenuItem
                  onClick={() => {
                    createRecipe(
                      {
                        name: "unnamed",
                        description: "",
                        timeMinutes: 1,
                        numberOfPortions: 2,
                        category: CATEGORY.dinner,
                        ingredients: [],
                        instructions: [],
                      },
                      navigate
                    );
                    closeMenu();
                  }}
                >
                  Create new recipe
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onSignOut();
                    closeMenu();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          )}
          {!authState.isAuthenticating && !authState.isAuthenticated && (
            <Button color="primary" onClick={openLoginModal}>
              Log in
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Hidden smUp>
        <AppBar position="static" color="transparent" elevation={1}>
          <BottomNavigation
            value={currentPath}
            onChange={(event: any, newPath: string) => {
              handleNavigationSelection(newPath);
            }}
            showLabels
            className={classes.bottomNav}
          >
            <BottomNavigationAction label="ShoppingLists" value="/shoppinglists" icon={<ListIcon />} />
            <BottomNavigationAction label="Recipes" value="/recipes" icon={<DescriptionIcon />} />
          </BottomNavigation>
        </AppBar>
      </Hidden>
      <LoginModal show={showLoginModal} onHide={closeLoginModal} />
    </Paper>
  );
}

const styles = () =>
  ({
    root: {
      width: 500,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    bottomNav: {
      width: "100%",
      position: "fixed",
      bottom: 0,
      top: "auto",
      boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      zIndex: 1,
    },
  } as const);

const mapStateToProps = (state: RootState) => ({
  authState: state.authState,
  recipeState: state.recipeState,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSignOut: () => dispatch(signOutRequest()),
  createRecipe: (recipe: Recipe, navigate: NavigateFunction) => {
    dispatch(createRecipeRequest(recipe, dispatch, navigate));
  },
  getRecipes: () => dispatch(getRecipesRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NavigationComponent));
